// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {
  ApiGatewayPathBuilder,
  CloudFunctionsPathBuilder,
  Env,
  IConsumerEnvironment,
  buildTenantConfig,
  envToProjectId,
  getEnvDomain,
} from 'shared-lib'

const env: Env = 'ci'
const projectId = envToProjectId[env]

const apiGatewayBuilder = new ApiGatewayPathBuilder(env)
const cloudFunctionsBuilder = new CloudFunctionsPathBuilder(env)

export const environment: IConsumerEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyAyd-cbCmAvVM-J607jwrdayoP0hOjW_3E',
    appId: '1:918338752368:web:f66ba0a731b5391598c9b5',
    authDomain: `${projectId}.firebaseapp.com`,
    measurementId: 'G-TYY20NGJ8P',
    messagingSenderId: '918338752368',
    projectId,
    storageBucket: `${projectId}.appspot.com`,
  },
  services: {
    auth: apiGatewayBuilder.build('auth'),
    cloudFunctions: cloudFunctionsBuilder.build(),
    commerce: apiGatewayBuilder.build('commerce'),
    content: apiGatewayBuilder.build('content'),
    customer: apiGatewayBuilder.build('customer'),
    events: apiGatewayBuilder.build('events'),
    files: apiGatewayBuilder.build('files'),
    integration: apiGatewayBuilder.build('integration'),
    messaging: apiGatewayBuilder.build('messaging'),
    notifications: apiGatewayBuilder.build('notifications'),
    product: apiGatewayBuilder.build('product'),
    productItem: apiGatewayBuilder.build('productItem'),
    registration: apiGatewayBuilder.build('registration'),
    seller: apiGatewayBuilder.build('seller'),
    user: apiGatewayBuilder.build('user'),
  },

  _business_EmailActionRoute: `https://${getEnvDomain(env, 'app.instockdepot.com')}/#/auth/actions`,
  algoliaAppId: '84GGKHXVIS',
  algoliaSearchKey: 'd157dc809bbfd4799c9fc6e03e0f4ff0',
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  basePlatformHost: getEnvDomain(env, 'app.instockdepot.com'),
  cdnCname: getEnvDomain(env, 'content.instockdepot.com'),
  emailVerificationEmailTemplateId: '2LKYubs8e8JqMm17XrZW',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  stripePublishableKey: 'pk_test_51LLDaTHW4nrizR1iJ3St5EIgBAiFyyPBBIP5v1INixoA0jK713KXkyFmCAzQKXAJf9UsiR8s92AxdJwmDrgnYXw700i5NiYmrx',
  tenant: buildTenantConfig(env),
  tenantId: 'B2C-USER-czoam',
  themesEnabled: true,
  v2ReCaptcha: '6LdQXUwgAAAAAMM0FO5OgzRt1RuQl6dqw-7OlLjE',
  version: '50ba14a',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
